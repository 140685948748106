<template>
  <div class="settingsBackground" id="settingsAll">
    <div id="appbar">
      <text @click="navToHome" class="title" id="hiddenTitle"
        ><i class="fas fa-broadcast-tower" id="logo"></i> DDC IoT Platform</text
      >
      <button
        type="button"
        id="navButtonHome"
        class="btn btn-success"
        @click="navToHome"
      >
        Home
      </button>
      <button
        type="button"
        id="navButtonDomains"
        class="btn btn-success"
        @click="navToDomains"
      >
        Domains
      </button>
      <button
        type="button"
        id="navButtonDevices"
        class="btn btn-success"
        @click="navToDevices"
      >
        Devices
      </button>
      <button
        type="button"
        id="navButtonUsers"
        class="btn btn-success"
        @click="navToUsers"
      >
        Users
      </button>
      <router-link to="/domains" class="Router-link"
        ><i class="fas fa-layer-group"></i>
      </router-link>
      <router-link to="/devices" class="Router-link"
        ><i class="fas fa-chalkboard"></i
      ></router-link>
      <router-link to="/users" class="Router-link"
        ><i class="fas fa-user-friends"></i> Users</router-link
      >
      <div id="profileIcon">
        <button
          type="button"
          class="btn btn-success"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          id="logoutButton"
          @click="profilename"
        >
          <label class="usernameText">{{ this.user }}</label>
          <i class="fas fa-user-alt" id="pIcon"></i>
        </button>
        <div class="dropdown-menu">
          <a class="dropdown-item"
            >Settings <i class="fas fa-cogs" id="settingsIcon"></i
          ></a>
          <a @click="logout" class="dropdown-item"
            >Logout <i class="fas fa-sign-out-alt" id="logoutIcon"></i
          ></a>
        </div>
      </div>
    </div>
    <div class="settingsCard" id="settingsContainer">
      <h3 class="settingsTitle">Settings</h3>
      <div class="input-group mb-3" id="timeContainer">
        <div class="input-group-prepend" >
          <label class="input-group-text"  id="timeButton" for="timeSelect"
            >Time Zone:</label
          >
        </div>
        <select class="custom-select" id="timeSelect" >
          <option selected>{{this.defaultLabel}}</option>
          <option v-for="i in 26"
                      :key="i"
                      @click="defaultTimeZone(i)"
                     >
            {{ timeZones[i][0] }}</option>



        </select>
      </div>
      <br />
      <div class="settingsLine">
        <label class="settingsLabel">Notifications</label>
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckDefault"
          />
        </div>
      </div>
      <div class="settingsLine">
        <label class="settingsLabel">Theme:</label>
        <button
          type="button"
          id="theme4"
          @click="selectThemeBlue"
          class="btn btn-primary btn-circle"
        ></button
        ><button
          type="button"
          id="theme1"
          @click="selectThemeLight"
          class="btn btn-primary btn-circle"
        ></button
        ><button
          type="button"
          id="theme2"
          @click="selectThemeDark"
          class="btn btn-primary btn-circle"
        ></button
        ><button
          type="button"
          id="theme3"
          @click="selectThemeGreen"
          class="btn btn-primary btn-circle"
        ></button>
        <button
          type="button"
          id="theme5"
          @click="selectThemeDarkCyan"
          class="btn btn-primary btn-circle"
        ></button>
        <br />
      </div>

      <div class="languageLine">
        <label class="settingsLabel">Language:</label>
        <div class="flag1"><span class="tooltiptext">Tooltip text</span></div>
        <div class="flag2"></div>
        <div class="flag3"></div>
        <div class="flag4"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ddcIotAuthProvider from "../utils/ddcIotAuthProvider.js";
const apiUrl = process.env.VUE_APP_API_URL || "http://localhost";

const authProvider = ddcIotAuthProvider(apiUrl);



export default {

  data() {
    return{
     
     defaultLabel: "Choose",

     timeZones: [
        ["UTC-12:00", -12],
        ["UTC-11:00", -11],
        ["UTC-10:00", -10],
        ["UTC-09:00", -9],
        ["UTC-08:00", -8],
        ["UTC-07:00", -7],
        ["UTC-06:00", -6],
        ["UTC-05:00", -5],
        ["UTC-04:00", -4],
        ["UTC-03:00", -3],
        ["UTC-02:00", -2],
        ["UTC-01:00", -1],
        ["UTC-00:00", +0],
        ["UTC+01:00", +1],
        ["UTC+02:00", +2],
        ["UTC+03:00", +3],
        ["UTC+04:00", +4],
        ["UTC+05:00", +5],
        ["UTC+06:00", +6],
        ["UTC+07:00", +7],
        ["UTC+08:00", +8],
        ["UTC+09:00", +9],
        ["UTC+10:00", +10],
        ["UTC+11:00", +11],
        ["UTC+12:00", +12],
        ["UTC+13:00", +13],
        ["UTC+14:00", +14],
      ],
    }
    },
  
  methods: {
    navToDevices() {
      this.$router.push("/devices");
    },

    navToHome() {
      this.$router.push("/");
    },

    navToDomains() {
      this.$router.push("/domains");
    },

    navToUsers() {
      this.$router.push("/users");
    },

    timeZoneLabel(){
      if(localStorage.getItem("defaultTimeZone")){
      this.defaultLabel = "UTC " + localStorage.getItem("defaultTimeZone") + ":00";
      }
      else {
        this.defaultLabel = "Choose..."
      }
    },

    defaultTimeZone(index){
      localStorage.setItem("defaultTimeZone", this.timeZones[index][1]);
    },

    logout() {
      authProvider.logout();
      this.loggedIn = false;
      this.$router.push("/Login");
      //console.log(this.loggedIn);
      localStorage.setItem("username", "");
    },

    selectThemeDark() {
      localStorage.setItem("theme", "dark");

      document.getElementById("settingsAll").style.backgroundColor = "#2a323d";
      document.getElementById("settingsContainer").style.backgroundColor =
        "#212529";
      document.getElementById("appbar").style.backgroundColor = "#212529";
      document.getElementById("settingsContainer").style.color = "white";
      document.getElementById("appbar").style.color = "white";

      document.getElementById("navButtonHome").style.backgroundColor =
        "#212529";
      document.getElementById("navButtonHome").style.color = "white";
      document.getElementById("navButtonDevices").style.backgroundColor =
        "#212529";
      document.getElementById("navButtonDevices").style.color = "white";
      document.getElementById("navButtonDomains").style.backgroundColor =
        "#212529";
      document.getElementById("navButtonDomains").style.color = "white";
      document.getElementById("navButtonUsers").style.backgroundColor =
        "#212529";
      document.getElementById("navButtonUsers").style.color = "white";

      document.getElementById("logoutButton").style.backgroundColor = "#212529";
      document.getElementById("logoutButton").style.color = "white";

      document.getElementById("logo").style.color = "#42ba85";
    },

    selectThemeLight() {
      localStorage.setItem("theme", "light");
      document.getElementById("settingsAll").style.backgroundColor = "white";
      document.getElementById("settingsContainer").style.backgroundColor =
        "#d2d3db";
      document.getElementById("appbar").style.backgroundColor = "#d2d3db";
      document.getElementById("settingsContainer").style.color = "black";
      document.getElementById("appbar").style.color = "black";

      document.getElementById("navButtonHome").style.backgroundColor =
        "#d2d3db";
      document.getElementById("navButtonHome").style.color = "black";
      document.getElementById("navButtonDevices").style.backgroundColor =
        "#d2d3db";
      document.getElementById("navButtonDevices").style.color = "black";
      document.getElementById("navButtonDomains").style.backgroundColor =
        "#d2d3db";
      document.getElementById("navButtonDomains").style.color = "black";
      document.getElementById("navButtonUsers").style.backgroundColor =
        "#d2d3db";
      document.getElementById("navButtonUsers").style.color = "black";

      document.getElementById("logoutButton").style.backgroundColor = "#d2d3db";
      document.getElementById("logoutButton").style.color = "black";

      document.getElementById("logo").style.color = "black";
    },

    selectThemeBlue() {
      localStorage.setItem("theme", "blue");
      document.getElementById("settingsAll").style.backgroundColor = "white";
      document.getElementById("settingsContainer").style.backgroundColor =
        "dodgerblue";
      document.getElementById("appbar").style.backgroundColor = "dodgerblue";
      document.getElementById("settingsContainer").style.color = "white";
      document.getElementById("appbar").style.color = "white";

      document.getElementById("navButtonHome").style.backgroundColor =
        "dodgerblue";
      document.getElementById("navButtonHome").style.color = "white";
      document.getElementById("navButtonDevices").style.backgroundColor =
        "dodgerblue";
      document.getElementById("navButtonDevices").style.color = "white";
      document.getElementById("navButtonDomains").style.backgroundColor =
        "dodgerblue";
      document.getElementById("navButtonDomains").style.color = "white";
      document.getElementById("navButtonUsers").style.backgroundColor =
        "dodgerblue";
      document.getElementById("navButtonUsers").style.color = "white";

      document.getElementById("logoutButton").style.backgroundColor =
        "dodgerblue";
      document.getElementById("logoutButton").style.color = "white";

      document.getElementById("logo").style.color = "white";
    },

    selectThemeGreen() {
      localStorage.setItem("theme", "green");
      document.getElementById("settingsAll").style.backgroundColor = "white";
      document.getElementById("appbar").style.backgroundColor = "#42ba85";
      document.getElementById("settingsContainer").style.backgroundColor =
        "#42ba85";
      document.getElementById("settingsContainer").style.color = "white";
      document.getElementById("appbar").style.color = "white";

      document.getElementById("navButtonHome").style.backgroundColor =
        "#42ba85";
      document.getElementById("navButtonHome").style.color = "white";
      document.getElementById("navButtonDomains").style.backgroundColor =
        "#42ba85";
      document.getElementById("navButtonDomains").style.color = "white";
      document.getElementById("navButtonDevices").style.backgroundColor =
        "#42ba85";
      document.getElementById("navButtonDevices").style.color = "white";
      document.getElementById("navButtonUsers").style.backgroundColor =
        "#42ba85";
      document.getElementById("navButtonUsers").style.color = "white";

      document.getElementById("logoutButton").style.backgroundColor = "#42ba85";
      document.getElementById("logoutButton").style.color = "white";

      document.getElementById("logo").style.color = "#2a323d";
    },

    selectThemeDarkCyan() {
      localStorage.setItem("theme", "cyan");
      document.getElementById("settingsAll").style.backgroundColor = "#2a323d";
      document.getElementById("appbar").style.backgroundColor = "#159fba";
      document.getElementById("settingsContainer").style.backgroundColor =
        "#159fba";
      document.getElementById("settingsContainer").style.color = "#2a323d";
      document.getElementById("appbar").style.color = "#2a323d";

      document.getElementById("navButtonHome").style.backgroundColor =
        "#159fba";
      document.getElementById("navButtonHome").style.color = "#2a323d";
      document.getElementById("navButtonDomains").style.backgroundColor =
        "#159fba";
      document.getElementById("navButtonDomains").style.color = "#2a323d";
      document.getElementById("navButtonDevices").style.backgroundColor =
        "#159fba";
      document.getElementById("navButtonDevices").style.color = "#2a323d";
      document.getElementById("navButtonUsers").style.backgroundColor =
        "#159fba";
      document.getElementById("navButtonUsers").style.color = "#2a323d";

      document.getElementById("logoutButton").style.backgroundColor = "#159fba";
      document.getElementById("logoutButton").style.color = "#2a323d";

      document.getElementById("logo").style.color = "#2a323d";
    },
  },

  mounted() {
    this.timeZoneLabel();
  }

};
</script>

<style>
.settingsBackground {
  background-color: #2a323d;
  width: 100vw;
  height: 100vh;
}

#timeSelect{
  width: 10vw;
  border: 1px solid white;
  background-color: #212529;
  color: white;
}

#timeButton{
  width: 5.5vw;
  background-color: #212529;
  color: white;
}

#timeContainer{
  position: relative;
  left: 40px;
  top: 7vh;
}

#flexSwitchCheckDefault {
  position: relative;
  top: 50px;
  left: 65px;
}

.settingsLine {
  display: flex;
}

#notification {
  position: absolute;
  right: 10px;
  top: 10px;
  min-width: 300px;
}

#theme1 {
  background-color: white;
  border: 1px solid dodgerblue;
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  line-height: 1.42857;
  position: relative;
  top: 40px;
  left: 100px;
  margin-right: 20px;
}

#theme2 {
  background-color: #212529;
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  line-height: 1.42857;
  position: relative;
  top: 40px;
  left: 100px;
  margin-right: 20px;
  border: 1px solid #42ba85;
}

#theme3 {
  background-color: #42ba85;
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  line-height: 1.42857;
  position: relative;
  top: 40px;
  left: 100px;
  margin-right: 20px;
  border: 1px solid black;
}

#theme4 {
  background-color: dodgerblue;
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  line-height: 1.42857;
  position: relative;
  top: 40px;
  left: 100px;
  margin-right: 20px;
  border: 1px solid white;
}

#theme5 {
  border: 1px #159fba solid;
  background-color: #212529;
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  line-height: 1.42857;
  position: relative;
  top: 40px;
  left: 100px;
  margin-right: 20px;
}

.settingsTitle {
  position: relative;
  left: 10px;
  top: 35px;
}

.settingsLabel {
  position: relative;
  left: 2vw;
  top: 5vh;
  margin-bottom: 20px;
}

.languageLine {
  display: flex;
}

.settingsCard {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 92.5vw;
  border-radius: 5px;
  top: 12vh;
  left: 4rem;
  z-index: 1;
  position: relative;
  background-color: #212529;
  color: white;
  height: 50vh;
}

.flag1 {
  background-image: url("../assets/hu.png");
  width: 40px;
  height: 20px;
  position: relative;
  left: 4vw;
  top: 5.5vh;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 10px;
}

.flag1 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  top: 40vh;

  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.flag2 {
  background-image: url("../assets/gb.png");
  width: 40px;
  height: 20px;
  left: 4vw;
  position: relative;
  top: 5.5vh;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 10px;
}
.flag3 {
  background-image: url("../assets/ge.png");
  width: 40px;
  height: 20px;
  left: 4vw;
  position: relative;
  top: 5.5vh;
  margin-right: 10px;
  background-size: cover;
}
.flag4 {
  background-image: url("../assets/ch.png");
  width: 40px;
  height: 20px;
  left: 4vw;
  position: relative;
  top: 5.5vh;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 10px;
}
</style>
